import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    ClickAwayListener,
    FormControlLabel,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import qs from 'qs';
import throttle from 'lodash/throttle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TuneIcon from '@mui/icons-material/Tune';
import MerchantTransactionItem from './MerchantTransactionItem';
import { User, UserContext } from '../../../context/UserContext';
import useSnackbar from '../../../hooks/useSnackbar';
import api from '../../../services/api';
import BasePageLayout from '../../../components/BasePageLayout';
import { datePickerStyles } from '../../../styles/date-picker-styles';
import { Token } from '../tokens/TokenDetailsPage';
import { UserTokenStatus } from '../../user/my-tokens/MyTokens';
import { mapTransactionsFromStrapiRes } from '../../user/transactions/utils';

enum TransactionStatus {
    Tossed = 'Tossed',
    Redeemed = 'Redeemed',
}

export interface HistoryRow {
    id: number;
    createdAt: string;
    event: UserTokenStatus;
    tosser_user: User;
    recipient_user: User;
}

export interface Transaction {
    id: number;
    token: Token;
    tosserPhoneNumber: string;
    recipientPhoneNumber: string;
    quantity: number;
    status: UserTokenStatus;
    createdAt: Date;
    updatedAt: Date;
    recipient_user: User;
    tosser_user: User;
    histories: HistoryRow[];
}

const statusMapping = {
    [TransactionStatus.Tossed]: [
        UserTokenStatus.Tossed,
        UserTokenStatus.Declined,
        UserTokenStatus.Spinning,
    ],
    [TransactionStatus.Redeemed]: [UserTokenStatus.Redeemed],
};

const MerchantTransactionsPage = () => {
    const {user} = useContext(UserContext);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const initialFilterState = {
        Tossed: false,
        Redeemed: false,
        dateFrom: null as Date | null,
        dateTo: null as Date | null,
        user: '',
    };
    const [filter, setFilter] = useState(initialFilterState);
    const [appliedFilters, setAppliedFilters] = useState<typeof filter>(filter);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const {showSnackbar, SnackbarComponent} = useSnackbar();

    const filterButtonRef = React.useRef<HTMLButtonElement>(null);

    const fetchTransactions = useCallback(
        async (page: number, reset = false) => {
            if (!user) {
                return;
            }

            setIsFetching(true);
            try {
                const conditions: any[] = [];

                conditions.push({token: {merchant: {id: {$eq: user.id}}}});

                const selectedStatuses = Object.values(TransactionStatus).filter(
                    (status) => appliedFilters[status as keyof typeof TransactionStatus]
                );

                if (selectedStatuses.length > 0) {
                    const mappedStatuses = selectedStatuses.flatMap(
                        (status) => statusMapping[status]
                    );
                    conditions.push({
                        status: {$in: mappedStatuses},
                    });
                }

                if (appliedFilters.dateFrom || appliedFilters.dateTo) {
                    const dateTo = appliedFilters.dateTo
                        ? new Date(appliedFilters.dateTo)
                        : null;

                    dateTo?.setDate(dateTo?.getDate() + 1);

                    conditions.push({
                        createdAt: {
                            ...(appliedFilters.dateFrom
                                ? {$gte: appliedFilters.dateFrom.toISOString()}
                                : {}),
                            ...(dateTo ? {$lt: dateTo.toISOString()} : {}),
                        },
                    });
                }

                if (appliedFilters.user) {
                    conditions.push({recipientPhoneNumber: {$containsi: appliedFilters.user}});
                }

                const query = qs.stringify(
                    {
                        sort: ['createdAt:desc'],
                        pagination: {
                            page,
                            pageSize: 10,
                        },
                        filters: conditions.length > 0 ? {$and: conditions} : {},
                        populate: {
                            token: {
                                populate: ['merchant'],
                            },
                            histories: {
                                populate: ['tosser_user', 'recipient_user']
                            },
                            recipient_user: true,
                            tosser_user: true,
                        },
                    },
                    {
                        encodeValuesOnly: true,
                    }
                );

                const response = await api.get(`/transactions?${query}`);

                const fetchedTransactions: Transaction[] = mapTransactionsFromStrapiRes(response);

                const {pageCount} = response.data.meta.pagination;
                setPageCount(pageCount);

                setTransactions((prevTransactions) =>
                    reset ? fetchedTransactions : [...prevTransactions, ...fetchedTransactions]
                );
            } catch (error) {
                setError('Error fetching transactions');
                showSnackbar('Error fetching transactions', 'error');
                console.error('Error fetching transactions:', error);
            } finally {
                setInitialLoading(false);
                setIsFetching(false);
            }
        },
        [appliedFilters, showSnackbar, user]
    );

    useEffect(() => {
        fetchTransactions(1, true);
    }, [fetchTransactions, appliedFilters]);

    useEffect(() => {
        const handleScroll = throttle(() => {
            if (
                window.innerHeight + document.documentElement.scrollTop + 100 >=
                document.documentElement.scrollHeight &&
                !isFetching &&
                page < pageCount
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        }, 200);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            handleScroll.cancel();
        };
    }, [isFetching, page, pageCount]);

    useEffect(() => {
        if (page > 1) {
            fetchTransactions(page);
        }
    }, [page, fetchTransactions]);

    const handleFilterChange = (field: keyof typeof filter, value: any) => {
        setFilter((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleApplyFilter = () => {
        setAppliedFilters({...filter});
        setPage(1);
        setTransactions([]);
        setFilterOpen(false);
    };

    const handleClearFilters = () => {
        setFilter(initialFilterState);
        setAppliedFilters(initialFilterState);
        setPage(1);
        setTransactions([]);
        setFilterOpen(false);
    };

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (filterButtonRef.current && filterButtonRef.current.contains(event.target as Node)) {
            return;
        }
        setFilterOpen(false);
    };

    const hasActiveFilters = () => {
        const {dateFrom, dateTo, user, ...statuses} = appliedFilters;
        return (
            Object.values(statuses).some((value) => value) ||
            dateFrom !== null ||
            dateTo !== null ||
            user.trim() !== ''
        );
    };

    return (
        <BasePageLayout>
            <Box
                sx={{
                    mb: 2,
                    mt: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontSize={16} mb={0}>
                    Transactions
                </Typography>
                <Button
                    sx={{
                        height: '25px',
                        width: '60px',
                        padding: 0,
                        backgroundColor: 'rgba(245, 245, 245, 1)',
                        color: 'rgba(57, 57, 67, 1)',
                        '&:hover': {
                            backgroundColor: 'rgba(57, 57, 67, 0.05)',
                        },
                    }}
                    ref={filterButtonRef}
                    onClick={() => setFilterOpen((prev) => !prev)}
                    startIcon={<TuneIcon sx={{fontSize: '12px !important'}}/>}
                    endIcon={<KeyboardArrowDownIcon sx={{fontSize: '12px !important'}}/>}
                ></Button>
            </Box>

            {hasActiveFilters() && (
                <Box sx={{mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1.5}}>
                    {Object.values(TransactionStatus).map((status) => {
                        if (appliedFilters[status as keyof typeof TransactionStatus]) {
                            return (
                                <Chip
                                    key={status}
                                    label={status}
                                    size="small"
                                    deleteIcon={
                                        <Close
                                            sx={{
                                                fontSize: '12px !important',
                                                color: 'white !important',
                                            }}
                                        />
                                    }
                                    sx={{
                                        fontSize: '10px',
                                        fontWeight: '500',
                                        borderRadius: '9px',
                                        backgroundColor: 'rgba(57, 57, 67, 1)',
                                        color: 'white !important',
                                    }}
                                    onDelete={() => {
                                        setFilter((prev) => ({
                                            ...prev,
                                            [status]: false,
                                        }));
                                        setAppliedFilters((prev) => ({
                                            ...prev,
                                            [status]: false,
                                        }));
                                        setPage(1);
                                        setTransactions([]);
                                    }}
                                />
                            );
                        }
                        return null;
                    })}

                    <Chip
                        label="Clear all"
                        size="small"
                        deleteIcon={
                            <Close
                                sx={{
                                    fontSize: '12px !important',
                                }}
                            />
                        }
                        sx={{
                            fontSize: '10px',
                            fontWeight: '500',
                            borderRadius: '9px',
                            border: '1px solid rgba(0, 0, 0, 0.5)',
                            backgroundColor: 'white',
                            color: 'rgba(28, 27, 31, 1) !important',
                        }}
                        onDelete={handleClearFilters}
                    />
                </Box>
            )}

            <SnackbarComponent/>

            {error ? (
                <Typography color="error">{error}</Typography>
            ) : initialLoading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    zIndex={1}
                    height="calc(100dvh - 180px)"
                >
                    <CircularProgress/>
                </Box>
            ) : (
                <Box>
                    {transactions.length > 0 ? (
                        transactions.map((transaction: Transaction) => (
                            <MerchantTransactionItem key={transaction.id} transaction={transaction}/>
                        ))
                    ) : (
                        <Typography>No transactions available.</Typography>
                    )}
                </Box>
            )}

            {isFetching && (
                <Box display="flex" justifyContent="center" alignItems="center" zIndex={1} mt={2}>
                    <CircularProgress/>
                </Box>
            )}

            {filterOpen && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Paper
                        elevation={3}
                        sx={{
                            position: 'absolute',
                            top: '133px',
                            right: '16px',
                            zIndex: 10,
                            bgcolor: 'white',
                            borderRadius: '12px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            p: 4,
                            width: '-webkit-fill-available',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100% - 295px)',
                            overflow: 'auto',
                            ml: 2,
                            mb: 4,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Stack gap={3}>
                            <Stack>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography fontWeight={500} sx={{mb: 1.5}}>
                                        By status
                                    </Typography>
                                    <Typography
                                        fontSize={12}
                                        fontWeight={600}
                                        sx={{
                                            color: 'black',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleClearFilters}
                                    >
                                        Clear all
                                    </Typography>
                                </Box>
                                {Object.values(TransactionStatus).map((status) => (
                                    <FormControlLabel
                                        key={status}
                                        control={
                                            <Checkbox
                                                checked={!!filter[status as keyof typeof filter]}
                                                sx={{padding: '5px'}}
                                                onChange={(e) =>
                                                    handleFilterChange(
                                                        status as keyof typeof filter,
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'rgba(132, 132, 132, 1)',
                                                    mb: 0,
                                                    ml: '3px',
                                                }}
                                            >
                                                {status}
                                            </Typography>
                                        }
                                        disableTypography
                                    />
                                ))}
                            </Stack>

                            <Stack>
                                <Typography fontWeight={500} sx={{mb: 2}}>
                                    By date range
                                </Typography>

                                <Box display="flex" alignItems="center" gap={1}>
                                    <DatePicker
                                        label="From"
                                        value={filter.dateFrom}
                                        onChange={(date) => handleFilterChange('dateFrom', date)}
                                        maxDate={filter.dateTo || undefined}
                                        slotProps={datePickerStyles}
                                    />
                                    <Typography mb={0}>-</Typography>
                                    <DatePicker
                                        label="To"
                                        value={filter.dateTo}
                                        onChange={(date) => handleFilterChange('dateTo', date)}
                                        minDate={filter.dateFrom || undefined}
                                        slotProps={datePickerStyles}
                                    />
                                </Box>
                            </Stack>

                            <Stack>
                                <Typography fontWeight={500} sx={{mb: 2}}>
                                    By user
                                </Typography>
                                <TextField
                                    placeholder="Enter phone number"
                                    value={filter.user}
                                    onChange={(e) => handleFilterChange('user', e.target.value)}
                                />
                            </Stack>
                        </Stack>

                        <Button
                            sx={{
                                mt: 4,
                                color: 'white',
                            }}
                            onClick={handleApplyFilter}
                        >
                            Apply
                        </Button>
                    </Paper>
                </ClickAwayListener>
            )}
        </BasePageLayout>
    );
};

export default MerchantTransactionsPage;