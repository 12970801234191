import React, { FC, useState } from 'react';
import { Avatar, Box, Collapse, Stack, Typography } from '@mui/material';
import { ImageOutlined } from '@mui/icons-material';
import { getDateTimeText } from '../../../services/utils';
import { Transaction } from '../../merchant/transactions/MerchantTransactions';
import { User } from '../../../context/UserContext';
import { UserTokenStatus } from '../my-tokens/MyTokens';
import { calculateTimeLeft } from '../../merchant/tokens/utils';
import { ReactComponent as TimerIcon } from '../../../icons/timer.svg';

interface UserTransactionItemProps {
    transaction: Transaction;
    user: User;
}

const UserTransactionItem: FC<UserTransactionItemProps> = ({transaction, user}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const transactionDate = new Date(transaction.createdAt);
    const isReceiver = transaction.recipientPhoneNumber === user.phoneNumber;

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    const formatPhoneNumber = (phoneNumber: string): string => {
        let cleaned = phoneNumber.replace(/^\+1/, '');
        cleaned = cleaned.replace(/\D/g, '');
        if (cleaned.length !== 10) {
            return phoneNumber;
        }
        return `+1 (${cleaned.slice(0, 3)})-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    };

    const expirationDate = new Date(new Date(transaction.updatedAt).getTime() + 72 * 60 * 60 * 1000);
    const timeLeft = calculateTimeLeft(expirationDate, true);

    const displayUsername = isReceiver ? (transaction.tosser_user?.username || transaction.tosser_user?.email) : (transaction.recipient_user?.username || transaction.recipient_user?.email);

    return (
        <Box
            mb={1.5}
            sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                bgcolor: 'rgba(245, 245, 245, 1)',
            }}
        >
            <Box
                onClick={handleClick}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                gap={2}
                sx={{
                    cursor: 'pointer'
                }}
            >
                <Box display="flex" alignItems="center">
                    <Avatar
                        sx={{
                            bgcolor: 'rgba(57, 57, 67, 1)',
                            mr: 2,
                            border: transaction.token.imageUrl ? '' : '1px solid black',
                            height: 40,
                            width: 40,
                        }}
                        src={transaction.token.imageUrl || ''}
                    >
                        {!transaction.token.imageUrl && <ImageOutlined sx={{color: 'white'}}/>}
                    </Avatar>
                    <Stack>
                        <Typography fontWeight={600} sx={{color: 'rgba(26, 29, 37, 1)'}}>
                            {transaction.status === UserTokenStatus.Tossed
                                ? 'Accepted'
                                : transaction.status}
                        </Typography>
                        <Typography
                            fontWeight={500}
                            fontSize={10}
                            sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                        >
                            {getDateTimeText(transactionDate)}
                        </Typography>
                    </Stack>
                </Box>

                <Stack textAlign="end" mr={1}>
                    <Typography fontWeight={600} sx={{color: 'rgba(26, 29, 37, 1)'}}>
                        {transaction.token.itemName} ({transaction.quantity})
                    </Typography>
                    <Typography
                        fontWeight={500}
                        fontSize={10}
                        sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                    >
                        {transaction.token.merchant?.username}
                    </Typography>
                </Stack>
            </Box>

            <Collapse in={isExpanded}>
                <Box mx={2} sx={{
                    width: 'calc(100% - 32px)',
                    height: '1px',
                    background: 'linear-gradient(to right, rgba(215, 220, 228, 0), rgba(215, 220, 228, 1), rgba(215, 220, 228, 0))',
                }}></Box>
                <Stack p={2} display="flex" flexDirection={'column'} gap={'4px'}>
                    <Typography mb={1.5} color={'rgba(31, 41, 55, 1)'} fontSize={12} fontWeight={500}>Transaction
                        Details</Typography>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={400} fontSize={10}>
                            {isReceiver ? 'Tosser' : 'Receiver'}:
                        </Typography>
                        <Box display={'flex'} gap={1}>
                            {displayUsername &&
                                <Typography mb={0} fontSize={10} color={'rgba(0, 0, 0, 0.5)'}>
                                    {displayUsername}
                                </Typography>}
                            <Typography fontSize={10} color={'rgba(0, 0, 0, 0.5)'}>
                                {formatPhoneNumber(
                                    isReceiver ? transaction.tosserPhoneNumber : transaction.recipientPhoneNumber
                                )}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={400} fontSize={10}>
                            Merchant:
                        </Typography>
                        <Typography fontSize={10} color={'rgba(0, 0, 0, 0.5)'}>
                            {transaction.token.merchant?.username}, {transaction.token.merchant?.address}
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={400} fontSize={10}>
                            Quantity:
                        </Typography>
                        <Typography fontSize={10} color={'rgba(0, 0, 0, 0.5)'}>
                            {transaction.quantity}
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={400} fontSize={10}>
                            Price:
                        </Typography>
                        <Typography fontSize={10} color={'rgba(0, 0, 0, 0.5)'}>
                            ${(+transaction.token.price * transaction.quantity * (1 - (transaction.token.discount) / 100)).toFixed(2)}
                        </Typography>
                    </Box>
                    {transaction.status === UserTokenStatus.Spinning &&
                        <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                            <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={400} fontSize={10}>
                                Expiration:
                            </Typography>
                            <Box display='flex' alignItems='center' gap='5px' sx={{
                                color: 'rgba(59, 186, 79, 1)',
                            }}>
                                <TimerIcon/>
                                <Typography
                                    fontWeight={700}
                                    color="inherit"
                                    fontSize={10}
                                    mb={0}
                                >
                                    {timeLeft}
                                </Typography>
                            </Box>
                        </Box>}
                    {transaction.histories?.length > 0 && <Stack mt={0.5} gap={1.5}>
                        <Typography mb={0.5} color={'rgba(31, 41, 55, 1)'} fontSize={12} fontWeight={500}>Transaction
                            History</Typography>
                        {transaction.histories.map((item) => (
                            <Box key={item.id} display={'flex'} justifyContent={'space-between'}>
                                {item.event === UserTokenStatus.Spinning && historyStatusTemplate('rgba(255, 166, 41, 1)', UserTokenStatus.Spinning)}
                                {item.event === UserTokenStatus.Declined && historyStatusTemplate('rgba(242, 72, 34, 1)', item.recipient_user ? `${UserTokenStatus.Declined} by ${item.recipient_user.username || item.recipient_user.email}` : UserTokenStatus.Declined)}
                                {(item.event === UserTokenStatus.Tossed && isReceiver) && historyStatusTemplate('rgba(59, 186, 79, 1)', item.tosser_user ? `Received from ${item.tosser_user.username || item.tosser_user.email}` : 'Received')}
                                {(item.event === UserTokenStatus.Tossed && !isReceiver) && historyStatusTemplate('rgba(66, 128, 239, 1)', item.recipient_user ? `Tossed to ${item.recipient_user.username || item.recipient_user.email}` : 'Tossed')}
                                {![UserTokenStatus.Spinning, UserTokenStatus.Declined, UserTokenStatus.Tossed].includes(item.event) && historyStatusTemplate('rgba(242, 72, 34, 1)', item.event)}
                                <Typography
                                    fontWeight={500}
                                    fontSize={10}
                                    mb={0}
                                    sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                                >
                                    {getDateTimeText(new Date(item.createdAt))}
                                </Typography>
                            </Box>
                        ))}
                    </Stack>}
                </Stack>
            </Collapse>
        </Box>
    );
};

const historyStatusTemplate = (color: string, text: string) => {
    return (<Box display={'flex'} gap={1} alignItems="center">
        <Box height={'8px'} width={'8px'} borderRadius={'50%'} bgcolor={color}/>
        <Typography mb={0} fontSize={10} fontWeight={400} color={'rgba(31, 41, 55, 1)'}>{text}</Typography>
    </Box>)
}

export default UserTransactionItem;