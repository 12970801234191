import React, { FC, useState } from 'react';
import { Avatar, Box, Collapse, Stack, Typography } from '@mui/material';
import { ImageOutlined } from '@mui/icons-material';
import { getDateTimeText } from '../../../services/utils';
import { HistoryRow, Transaction } from './MerchantTransactions';
import { UserTokenStatus } from '../../user/my-tokens/MyTokens';

interface MerchantTransactionItemProps {
    transaction: Transaction;
}

const MerchantTransactionItem: FC<MerchantTransactionItemProps> = ({transaction}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const transactionDate = new Date(transaction.createdAt);

    const displayStatus = transaction.status === UserTokenStatus.Redeemed ? 'Redeemed' : 'Tossed';

    const handleClick = () => {
        if (transaction.histories?.length) {
            setIsExpanded(!isExpanded);
        }
    };

    return (
        <Box
            mb={1.5}
            sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                bgcolor: 'rgba(245, 245, 245, 1)',
            }}
        >
            <Box
                onClick={handleClick}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                gap={2}
                sx={{
                    cursor: 'pointer',
                }}
            >
                <Box display="flex" alignItems="center">
                    <Avatar
                        sx={{
                            bgcolor: 'rgba(57, 57, 67, 1)',
                            mr: 2,
                            border: transaction.token.imageUrl ? '' : '1px solid black',
                            height: 40,
                            width: 40,
                        }}
                        src={transaction.token.imageUrl || ''}
                    >
                        {!transaction.token.imageUrl && <ImageOutlined sx={{color: 'white'}}/>}
                    </Avatar>
                    <Stack>
                        <Typography fontWeight={600} sx={{color: 'rgba(26, 29, 37, 1)'}}>
                            {displayStatus}
                        </Typography>
                        <Typography
                            fontWeight={500}
                            fontSize={10}
                            sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                        >
                            {getDateTimeText(transactionDate)}
                        </Typography>
                    </Stack>
                </Box>

                <Stack textAlign="end" mr={1}>
                    <Typography fontWeight={600} sx={{color: 'rgba(26, 29, 37, 1)'}}>
                        {transaction.recipient_user ? (transaction.recipient_user.username || transaction.recipient_user.email) : transaction.recipientPhoneNumber}
                    </Typography>
                    <Typography
                        fontWeight={500}
                        fontSize={10}
                        sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                    >
                        {transaction.token.itemName} ({transaction.quantity})
                    </Typography>
                </Stack>
            </Box>

            <Collapse in={isExpanded}>
                <Box mx={2} sx={{
                    width: 'calc(100% - 32px)',
                    height: '1px',
                    background: 'linear-gradient(to right, rgba(215, 220, 228, 0), rgba(215, 220, 228, 1), rgba(215, 220, 228, 0))',
                }}></Box>

                <Stack p={2} gap={1.5}>
                    <Typography
                        mb={0.5}
                        color={'rgba(31, 41, 55, 1)'}
                        fontSize={12}
                        fontWeight={500}
                    >
                        Transaction History
                    </Typography>

                    {transaction.histories?.map((item) => (
                        <Box key={item.id} display="flex" justifyContent="space-between">
                            {renderHistoryEvent(item)}
                            <Typography
                                fontWeight={500}
                                fontSize={10}
                                mb={0}
                                sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                            >
                                {getDateTimeText(new Date(item.createdAt))}
                            </Typography>
                        </Box>
                    ))}
                </Stack>

            </Collapse>
        </Box>
    );
};

export default MerchantTransactionItem;

function renderHistoryEvent(item: HistoryRow) {
    switch (item.event) {
        case UserTokenStatus.Spinning:
            return historyStatusTemplate(
                'rgba(255, 166, 41, 1)',
                item.recipient_user
                    ? `Tossed to ${item.recipient_user.username || item.recipient_user.email || item.recipient_user.phoneNumber}`
                    : 'Tossed',
            );

        case UserTokenStatus.Tossed:
            return historyStatusTemplate(
                'rgba(59, 186, 79, 1)',
                item.tosser_user
                    ? `Received from ${item.tosser_user.username || item.tosser_user.email || item.tosser_user.phoneNumber}`
                    : 'Received',
            );

        case UserTokenStatus.Declined:
            return historyStatusTemplate(
                'rgba(242, 72, 34, 1)',
                item.recipient_user
                    ? `Declined by ${item.recipient_user.username || item.recipient_user.email || item.recipient_user.phoneNumber}`
                    : 'Declined',
            );

        default:
            return historyStatusTemplate('rgba(242, 72, 34, 1)', item.event);
    }
}

function historyStatusTemplate(color: string, text: string) {
    return (
        <Box display="flex" gap={1} alignItems="center">
            <Box height="8px" width="8px" borderRadius="50%" bgcolor={color}/>
            <Typography mb={0} fontSize={10} fontWeight={400} color="rgba(31, 41, 55, 1)">
                {text}
            </Typography>
        </Box>
    );
}