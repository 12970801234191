import BasePageLayout from '../../components/BasePageLayout';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import QRCode from "react-qr-code";
import { BoughtToken, UserTokenStatus } from './my-tokens/MyTokens';
import UseTokenDialog from './my-tokens/UseTokenDialog';
import api from '../../services/api';
import useSnackbar from '../../hooks/useSnackbar';

const ScanQr = () => {
    const location = useLocation();
    const {showSnackbar, SnackbarComponent} = useSnackbar();

    const [tokenToConsume, setTokenToConsume] = useState<BoughtToken | null>(null);

    const {useToken} = location?.state ? (location.state as { useToken?: BoughtToken }) : {
        useToken: null,
    };

    const navigate = useNavigate();

    const confirmUseToken = async (boughtToken: BoughtToken) => {
        try {
            setTokenToConsume(null);

            const newQuantity = boughtToken.quantity - 1;

            const updateBoughtTokenData = {
                data: {
                    quantity: newQuantity,
                    status: newQuantity === 0 ? UserTokenStatus.Redeemed : boughtToken.status,
                },
            };

            const newTransactionData = {
                data: {
                    token: boughtToken.token.id,
                    tosserPhoneNumber: boughtToken.tosserPhoneNumber,
                    recipientPhoneNumber: boughtToken.recipientPhoneNumber,
                    quantity: 1,
                    status: UserTokenStatus.Redeemed,
                    recipient_user: (boughtToken.transaction?.recipient_user as any)?.data?.id,
                    tosser_user: (boughtToken.transaction?.tosser_user as any)?.data?.id
                },
            };

            await Promise.all([
                api.put(`/bought-tokens/${boughtToken.id}`, updateBoughtTokenData),
                api.post('/transactions', newTransactionData),
            ]);

            navigate('/my-tokens');
        } catch (error) {
            console.error('Error using token:', error);
            showSnackbar('Failed to use token', 'error');
        }
    };

    return (
        <BasePageLayout>
            <Stack justifyContent='space-around' gap={4} alignItems='center' height={'calc(90dvh - 90px - 90px)'}>
                <Stack alignItems={'center'} gap={2}>
                    <Typography color={'rgba(117, 117, 117, 1)'} fontSize={16}>Show Your Qr Code to a
                        Server</Typography>
                    <Typography color={'rgba(30, 30, 30, 1)'} fontSize={24}>Scan your QR</Typography>
                    <QRCode value={'https://dev.tokntoss.com/'}/>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setTokenToConsume(useToken as BoughtToken)}
                    >
                        Use token
                    </Button>
                </Stack>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => navigate('/home')}
                >
                    Home
                </Button>
            </Stack>

            <UseTokenDialog
                tokenToConsume={tokenToConsume}
                onClose={() => setTokenToConsume(null)}
                onConfirm={confirmUseToken}
            />
            <SnackbarComponent/>
        </BasePageLayout>
    );
};

export default ScanQr;