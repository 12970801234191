import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, Tune as TuneIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import TokenItem from './TokenItem';
import FilterMenu from './FilterMenu';
import { Token } from '../../merchant/tokens/TokenDetailsPage';
import { Transaction } from '../../merchant/transactions/MerchantTransactions';
import { UserContext } from '../../../context/UserContext';
import useSnackbar from '../../../hooks/useSnackbar';
import api from '../../../services/api';
import BasePageLayout from '../../../components/BasePageLayout';

export enum UserTokenStatus {
    Declined = 'Declined',
    Tossed = 'Tossed',
    Spinning = 'Spinning',
    Redeemed = 'Redeemed',
}

export interface BoughtToken {
    id: number;
    token: Token;
    tosserPhoneNumber: string;
    recipientPhoneNumber: string;
    quantity: number;
    expirationDateTime: string;
    status: UserTokenStatus;
    message?: string;
    transaction: Transaction;
}

type FilterState = {
    Declined: boolean;
    Tossed: boolean;
    Spinning: boolean;
    Redeemed?: boolean;
    showAll: boolean;
};

type FilterKey = keyof FilterState;

const MyTokens: React.FC = () => {
    const {user} = useContext(UserContext);
    const [boughtTokens, setBoughtTokens] = useState<BoughtToken[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [filter, setFilter] = useState<FilterState>({
        Declined: true,
        Tossed: true,
        Spinning: true,
        showAll: true,
    });
    const [appliedFilters, setAppliedFilters] = useState<FilterState>({
        Declined: true,
        Tossed: true,
        Spinning: true,
        showAll: true,
    });
    const filterButtonRef = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();
    const {showSnackbar, SnackbarComponent} = useSnackbar();

    const [messages, setMessages] = useState<{ [key: number]: string }>({});

    const fetchBoughtTokens = useCallback(async () => {
        if (!user) return;

        setInitialLoading(true);
        setError(null);

        try {
            const response = await api.get('/bought-tokens', {
                params: {
                    filters: {
                        $or: [
                            {
                                $and: [
                                    {recipientPhoneNumber: {$eq: user.phoneNumber}},
                                    {status: {$in: [UserTokenStatus.Spinning, UserTokenStatus.Tossed]}},
                                ],
                            },
                            {
                                $and: [
                                    {tosserPhoneNumber: {$eq: user.phoneNumber}},
                                    {status: {$eq: UserTokenStatus.Declined}},
                                ],
                            },
                        ],
                    },
                    populate: {
                        token: {
                            populate: ['merchant'],
                        },
                        transaction: {
                            populate: ['token', 'histories', 'recipient_user', 'tosser_user'],
                        },
                    },
                },
            });

            const fetchedTokens: BoughtToken[] = response.data.data.map((item: any) => {
                const tokenData = item.attributes.token.data;
                const tokenAttributes = tokenData.attributes;
                const merchantData = tokenAttributes.merchant.data;
                const transactionData = item.attributes.transaction?.data;
                const transactionAttributes = transactionData?.attributes;

                return {
                    id: item.id,
                    ...item.attributes,
                    token: {
                        id: tokenData.id,
                        ...tokenAttributes,
                        merchant: {
                            id: merchantData?.id,
                            ...merchantData?.attributes,
                        },
                    },
                    transaction: transactionData
                        ? {
                            id: transactionData.id,
                            ...transactionAttributes,
                            token: {
                                id: tokenData.id,
                                ...tokenAttributes,
                                merchant: {
                                    id: merchantData?.id,
                                    ...merchantData?.attributes,
                                },
                            },
                            createdAt: new Date(transactionAttributes.createdAt),
                        }
                        : null,
                };
            });

            setBoughtTokens(fetchedTokens);

            const initialMessages = fetchedTokens.reduce((acc, token) => {
                acc[token.id] = token.message || '';
                return acc;
            }, {} as { [key: number]: string });
            setMessages(initialMessages);
        } catch (err) {
            console.error('Error fetching bought tokens:', err);
            setError('Error fetching tokens');
        } finally {
            setInitialLoading(false);
        }
    }, [user]);

    useEffect(() => {
        fetchBoughtTokens();
    }, [fetchBoughtTokens]);

    const handleFilterChange = (field: FilterKey, value?: boolean) => {
        setFilter((prev) => {
            const newValue = value || !prev[field];
            const newFilter = {...prev, [field]: newValue};

            const allSelected = ([UserTokenStatus.Declined, UserTokenStatus.Tossed, UserTokenStatus.Spinning] as FilterKey[]).every(
                (key) => newFilter[key]
            );

            return {...newFilter, showAll: allSelected};
        });
    };

    const handleApplyFilter = () => {
        setAppliedFilters({...filter});
        setFilterOpen(false);
    };

    const handleApprove = async (boughtToken: BoughtToken) => {
        try {
            if (isProcessing) return;

            setIsProcessing(true);

            await api.post(`/bought-tokens/approve`, {
                boughtTokenId: boughtToken.id,
                currentUserId: user!.id,
                message: messages[boughtToken.id]
            });

            setBoughtTokens((prevTokens) =>
                prevTokens.map((token) =>
                    token.id === boughtToken.id
                        ? {
                            ...token,
                            status: UserTokenStatus.Tossed,
                            message: messages[boughtToken.id],
                            transaction: {...token.transaction!, status: UserTokenStatus.Tossed},
                        }
                        : token
                )
            );
            showSnackbar('Token approved successfully', 'success');
        } catch (err) {
            console.error('Error approving token:', err);
            showSnackbar('Failed to approve token', 'error');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleDecline = async (boughtToken: BoughtToken) => {
        try {
            if (isProcessing) return;

            setIsProcessing(true);

            await api.post(`/bought-tokens/decline`, {
                boughtTokenId: boughtToken.id,
                currentUserId: user!.id,
                message: messages[boughtToken.id]
            });

            setBoughtTokens((prevTokens) =>
                prevTokens.map((token) =>
                    token.id === boughtToken.id
                        ? {
                            ...token,
                            status: UserTokenStatus.Declined,
                            recipientPhoneNumber: '',
                            message: messages[boughtToken.id],
                            transaction: {...token.transaction!, status: UserTokenStatus.Declined},
                        }
                        : token
                )
            );
            showSnackbar('Token declined successfully', 'success');
        } catch (err) {
            console.error('Error declining token:', err);
            showSnackbar('Failed to decline token', 'error');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleUseToken = (useToken: BoughtToken) => {
        navigate('/scan-qr', {state: {useToken}});
    };

    const handleMessageChange = (tokenId: number, newMessage: string) => {
        setMessages((prevMessages) => ({
            ...prevMessages,
            [tokenId]: newMessage,
        }));
    };

    const filteredTokens = boughtTokens.filter((token) => {
        if (appliedFilters.showAll) {
            return true;
        }

        return appliedFilters[token.status];
    });

    const spinningTokens = filteredTokens.filter(
        (token) => token.status === UserTokenStatus.Spinning && token.recipientPhoneNumber === user?.phoneNumber
    );
    const activeTokens = filteredTokens.filter(
        (token) => token.status === UserTokenStatus.Tossed && token.recipientPhoneNumber === user?.phoneNumber
    );
    const declinedTokens = filteredTokens.filter(
        (token) => token.status === UserTokenStatus.Declined && token.tosserPhoneNumber === user?.phoneNumber
    );

    return (
        <BasePageLayout>
            <Box
                sx={{
                    mb: 2,
                    mt: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontSize={16} mb={0}>
                    My Tokens
                </Typography>
                <Box position="relative">
                    <Button
                        ref={filterButtonRef}
                        sx={{
                            height: '25px',
                            width: '60px',
                            padding: 0,
                            backgroundColor: 'rgba(245, 245, 245, 1)',
                            color: 'rgba(57, 57, 67, 1)',
                            '&:hover': {
                                backgroundColor: 'rgba(57, 57, 67, 0.05)',
                            },
                        }}
                        onClick={() => setFilterOpen((prev) => !prev)}
                        startIcon={<TuneIcon sx={{fontSize: '12px !important'}}/>}
                        endIcon={<KeyboardArrowDownIcon sx={{fontSize: '12px !important'}}/>}
                    ></Button>
                    <FilterMenu
                        filter={filter}
                        onFilterChange={handleFilterChange}
                        onApplyFilter={handleApplyFilter}
                        filterOpen={filterOpen}
                        setFilterOpen={setFilterOpen}
                        filterButtonRef={filterButtonRef}
                    />
                </Box>
            </Box>

            {initialLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" zIndex={1}
                     height="calc(100dvh - 180px)">
                    <CircularProgress/>
                </Box>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <>
                    {spinningTokens.length > 0 && (
                        <Box mb={2}>
                            <Typography fontSize={12} mb={2}>
                                Spinning Tokens
                            </Typography>
                            {spinningTokens.map((userToken) => (
                                <TokenItem
                                    key={userToken.id}
                                    userToken={userToken}
                                    status={UserTokenStatus.Spinning}
                                    onApprove={handleApprove}
                                    onDecline={handleDecline}
                                    message={messages[userToken.id]}
                                    onMessageChange={handleMessageChange}
                                    loading={isProcessing}
                                />
                            ))}
                        </Box>
                    )}

                    {activeTokens.length > 0 && (
                        <Box mb={3}>
                            <Typography fontSize={12} mb={2}>
                                Active Tokens
                            </Typography>
                            {activeTokens.map((userToken) => (
                                <TokenItem
                                    key={userToken.id}
                                    userToken={userToken}
                                    status={UserTokenStatus.Tossed}
                                    onUse={handleUseToken}
                                />
                            ))}
                        </Box>
                    )}

                    {declinedTokens.length > 0 && (
                        <Box mb={3}>
                            <Typography fontSize={12} mb={2}>
                                Declined Tokens
                            </Typography>
                            {declinedTokens.map((userToken) => (
                                <TokenItem key={userToken.id} userToken={userToken} status={UserTokenStatus.Declined}
                                           navigate={navigate}/>
                            ))}
                        </Box>
                    )}

                    {spinningTokens.length === 0 && activeTokens.length === 0 && declinedTokens.length === 0 && (
                        <Typography>No tokens available.</Typography>
                    )}
                </>
            )}

            <SnackbarComponent/>
        </BasePageLayout>
    );
};

export default MyTokens;