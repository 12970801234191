import { Token } from '../../merchant/tokens/TokenDetailsPage';
import { Transaction } from '../../merchant/transactions/MerchantTransactions';

export const mapTransactionsFromStrapiRes = (res: any): Transaction[] => {
    return res.data.data.map((item: any) => {
        const transactionAttributes = item.attributes;
        const tokenData = transactionAttributes.token.data;
        const tokenAttributes = tokenData.attributes;
        const merchantData = tokenAttributes.merchant?.data;
        const recipient_user_data = transactionAttributes.recipient_user?.data;
        const recipient_user = recipient_user_data ? {id: recipient_user_data?.id, ...recipient_user_data?.attributes} : recipient_user_data;
        const tosser_user_data = transactionAttributes.tosser_user?.data;
        const tosser_user = tosser_user_data ? {id: tosser_user_data?.id, ...tosser_user_data?.attributes} : tosser_user_data;
        const histories = transactionAttributes.histories?.data.map((hist: any) => ({
            id: hist.id, ...hist.attributes,
            tosser_user: hist.attributes.tosser_user?.data?.attributes,
            recipient_user: hist.attributes.recipient_user?.data?.attributes
        }));

        const token: Token = {
            id: tokenData.id,
            ...tokenAttributes,
            merchant: merchantData ? {
                id: merchantData?.id,
                ...merchantData?.attributes,
            } : merchantData,
        };

        const transaction: Transaction = {
            id: item.id,
            ...transactionAttributes,
            token: token,
            createdAt: new Date(transactionAttributes.createdAt),
            recipient_user,
            tosser_user,
            histories
        };

        return transaction;
    });
};